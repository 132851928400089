import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListData } from 'interface/Data';

import { typeCookieEmail, typeCookiePassw } from 'fixedData';

import { Access } from 'services/AccessLogin';

const cookies = new Cookies();

const root = ReactDOM.createRoot(document.getElementById('root'));

const data = new FormData();
data.append('type_page', 'show_data_initial');

if(cookies.get(typeCookieEmail)){
    Access('return', cookies.get(typeCookieEmail), cookies.get(typeCookiePassw), ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });

}else {
    CallbackError();
}

function CallbackSuccess(){
    Axios({
        url     : process.env.REACT_APP_PHP + 'inc/list_data.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListData(response.data);

        root.render(
            <React.Fragment>
                <App />
            </React.Fragment>
        );
    });
}

function CallbackError(){ 
    root.render(
        <React.Fragment>
            <App loadingDataPage={ CallbackSuccess } />
        </React.Fragment>
    );
}