import { useState, useEffect } from "react";

import { Svg_Arrow, Svg_Logo, Svg_User } from "components/Svg";

import { GetUserData } from "interface/Users";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";

import Page_Downloads from "pages/Downloads";
import Page_Architect_Profile from "pages/Architect/Profile";
import Page_Architect_Projects from "pages/Architect/Projects";
import Page_Architect_Projects_Details from "pages/Architect/Projects/Details";

export default function Contents_Architect(props){

    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ listOptDownload, setListOptDownload ] = useState([
        { "name": "Imagens", "page": "images" },
        { "name": "Catálogos", "page": "catalogs" },
        { "name": "Blocos 3D", "page": "3d_blocks" }
    ]);

    function CurrentPage(){
        switch (currentPage) {
            case "images":
                return <Page_Downloads type="none" name="Imagens" origin="images" setLoading={ props.setLoading } />;

            case "catalogs":
                return <Page_Downloads type="none" name="Catálogos" origin="catalogs" setLoading={ props.setLoading } />;

            case "3d_blocks":
                return <Page_Downloads type="none" name="3D Blocos" origin="3D_blocks" setLoading={ props.setLoading } />;

            case "architects":
                return <Page_Architect_Projects origin="" setLoading={ props.setLoading } />;
            case "architects_details":
                return <Page_Architect_Projects_Details origin="" setLoading={ props.setLoading } />;
        
            default:
                return <Page_Architect_Profile origin="" idPage={ GetUserData('idPage') } setLoading={ props.setLoading } />;
        }
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);
        return ()=>{
            UnRegisterListPag('currentPage', setCurrentPage);
        };
    }, []);

    return(
        <div className="Contents">
            <div className="div_menu">
                <div className="div_logo">
                    <Svg_Logo className="logo" color="#ffffff" />
                </div>
                <div className="opt_menu show_data_user">
                    <div className="div_data_img">
                        {
                            props.userFile == "" ? 
                            <Svg_User className="img_user" color="#ffffff" /> :
                            <img alt="user" src={ props.userFile } className="img_user" />
                        }
                    </div>
                    <div className="div_data_user">
                        <div className="hello">Olá,</div>
                        <div className="name_user">{ props.userName.split(' ')[0] + " " + props.userName.split(' ')[1] }</div>
                    </div>
                </div>
                <div className="opt_menu show_opt_menu">
                    <div className={ currentPage == "index" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('index') } }>
                        Editar perfil
                    </div>
                    <div className={ currentPage == "architects" || currentPage == "architects_details" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('architects') } }>
                        Meus Projetos
                    </div>
                    <div className="name_menu">
                        <div className="submenu">Downloads</div>
                        {
                            listOptDownload.map((elem, index)=>{
                                return(
                                    <div className={ currentPage == elem.page ? "submenu menu_active" : "submenu" } key={ index } onClick={ ()=>{ props.CkickPage(elem.page) } }>
                                        <Svg_Arrow className="icons" color="#ffffff" />
                                        { elem.name }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="name_menu exit" onClick={ ()=>{ props.Logout() } }>Sair</div>
                </div>
            </div>
            <div className="div_options">
                { CurrentPage() }
            </div>
        </div>
    )
}