import { useState, useEffect } from "react";

import './Profile.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Svg_Arrow, Svg_Delete, Svg_Edit, Svg_Link, Svg_Logo_Abbreviated } from "components/Svg";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { cepMask, cnpjMask } from "services/Mask";
import { Reg_Architects, Reg_CropImg } from "services/RegisterData";

export default function Page_Architects_Profile(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('architects'));

    const [ cover, setCover ] = useState(InitialData('cover'));
    const [ file, setFile ] = useState(InitialData('file'));

    const [ nameFantasy, setNameFantasy ] = useState(InitialData('name_fantasy'));
    const [ cnpj, setCnpj ] = useState(InitialData('cnpj'));
    const [ socialReason, setSocialReason ] = useState(InitialData('social_reason'));
    const [ stateRegistration, setStateRegistration ] = useState(InitialData('state_registration'));
    
    const [ idGiven, setIdGiven ] = useState(InitialData('id_given'));
    const [ name, setName ] = useState(InitialData('name'));
    const [ cau, setCau ] = useState(InitialData('cau'));

    const [ road, setRoad ] = useState(InitialData('road'));
    const [ number, setNumber ] = useState(InitialData('number'));
    const [ neighborhood, setNeighborhood ] = useState(InitialData('neighborhood'));
    const [ cep, setCep ] = useState(InitialData('cep'));
    const [ city, setCity ] = useState(InitialData('city'));
    const [ uf, setUf ] = useState(InitialData('uf'));

    const [ facebook, setFacebook ] = useState(InitialData('facebook'));
    const [ instagram, setInstagram ] = useState(InitialData('instagram'));
    const [ tiktok, setTiktok ] = useState(InitialData('tiktok'));
    const [ pinterest, setPinterest ] = useState(InitialData('pinterest'));
    const [ site, setSite ] = useState(InitialData('site'));
    
    const [ listContact, setListContact ] = useState(InitialData('list_contact'));

    const [ userName, setUserName ] = useState(DataLogin('name'));
    const [ userEmail, setUserEmail ] = useState(DataLogin('email'));
    const [ userPassword, setUserPassword ] = useState('');

    function InitialData(type){
        if(GetUserData('nivel') == 1){
            const newData = GetDataPage('architects').find(item => item.id == idPage);
            if(newData){
                return newData[type];
            }
            if(type == 'id_given'){
                return 0;
            }
            if(type == 'list_contact'){
                return [];
            }
            return '';
        }else {
            const newData = GetDataPage('architects').find(item => item.id == idPage);
            if(newData){
                return newData[type];
            }
            if(type == 'id_given'){
                return 0;
            }
            if(type == 'list_contact'){
                return [];
            }
            return '';
        } 
    }

    function DataLogin(type){
        const newData = GetDataPage('access_dash').find(item => item.id == InitialData('id_given'));
        if(newData){
            return newData[type];
        }
        return '';
    }

    function RegisterCropData(img){
        props.setLoading(true);
        Reg_CropImg('architects', userId, idGiven, GetUserData('nivel') == 1 ? props.idPage : idPage, img.type, img.file, img.positionX, img.positionY, img.width, img.height, img.showWidth, img.widthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CropImg(type, value, index){
        let proportionX = 0;
        let proportionY = 0;

        // file
        if(type == 'file'){
            proportionX = 16;
            proportionY = 16;
        // cover 
        }else if(type == 'cover'){
            proportionX = 16;
            proportionY = 3;
        }     
        SetModalData('PhotoCrop', { "type": type, "index": index, "showImg": URL.createObjectURL(value), "file": value, "RegisterCropData": RegisterCropData, "proportionX": proportionX, "proportionY": proportionY });   
        SetModalState('PhotoCrop', true);
    }

    /* contact */
    function HandleData(type, index, value){
        const newData = [...listContact];
        newData[index][type] = value;
        setListContact(newData);
    }
    function Delete(index, id, value){
        if(id == 0){
            const newData = [...listContact];
            newData.splice(index, 1);
            setListContact(newData);
        }else {
            SetModalData('Confirmation', { "type": "delete_contact", "status": 0, "id": id, "origin": "architects", "name": value });
            SetModalState('Confirmation', true);
        }
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Architects(userId, props.origin, idGiven, GetUserData('nivel') == 1 ? props.idPage : idPage, nameFantasy, cnpj, socialReason, stateRegistration, name, cau, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, tiktok, pinterest, site, userName, userEmail, userPassword, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterListPag('currentPageId', setIdPage);
        RegisterUserData('id', setUserId);
        RegisterDataPage('architects', setDataPage);

        return ()=>{
            UnRegisterListPag('currentPageId', setIdPage);
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('architects', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('architects'));
        
        setCover(InitialData('cover'));
        setFile(InitialData('file'));
        
        setIdGiven(InitialData('id_given'));
        setNameFantasy(InitialData('name_fantasy'));
        setCnpj(InitialData('cnpj'));
        setSocialReason(InitialData('social_reason'));
        setStateRegistration(InitialData('state_registration'));

        setName(InitialData('name'));
        setCau(InitialData('cau'));

        setRoad(InitialData('road'));
        setNumber(InitialData('number'));
        setNeighborhood(InitialData('neighborhood'));
        setCep(InitialData('cep'));
        setCity(InitialData('city'));
        setUf(InitialData('uf'));    

        setFacebook(InitialData('facebook'));
        setInstagram(InitialData('instagram'));
        setTiktok(InitialData('tiktok'));
        setPinterest(InitialData('pinterest'));
        setSite(InitialData('site'));

        setListContact(InitialData('list_contact'));

        setUserName(DataLogin('name'));
        setUserEmail(DataLogin('email'));
        setUserPassword('');
    }, [dataPage, idPage]);

    return(
        <form className="Page_Architects_Profile width_page" onSubmit={ SaveData }>
            {
                idPage != 0 ?
                <div className="div_file">
                    <div className={ cover ? "show_cover" : "show_cover not_cover" } style={ { backgroundImage: 'url("' + 
                    cover + '")' } }>
                        {
                            cover ? null : "Capa não encontrada"
                        }
                        <label>
                            <div className="alt_cover">
                                <input type="file" onChange={ (e)=>{ CropImg('cover', e.target.files[0], 0) } } accept="image/*" />
                                Editar capa
                            </div>
                        </label>
                    </div>
                    <div className="show_img">
                        <div className="file_user">
                            {
                                file == "" ? <Svg_Logo_Abbreviated className="icons" /> :
                                <img al="img" src={ file } className="icons" />
                            }                        
                        </div>
                        <div className="alt_file" data-tooltip-id="alt_file_user" data-tooltip-content="Alterar sua foto" data-tooltip-place="top">
                            <label>
                                <input type="file" onChange={ (e)=>{ CropImg('file', e.target.files[0], 0) } } accept="image/*" />
                                <Svg_Edit className="icons" color={ colorIcon } />
                            </label>
                        </div>
                        <Tooltip id="alt_file_user" />
                    </div>
                </div>
                : null
            }
            
            <div className="div_btn_save">
                {
                    props.origin ?
                    <>
                        <div className="topic_return_admin" onClick={ ()=>{ props.ReturnPage('architects') } }>
                            <Svg_Arrow className="icons" color={ colorIcon } />
                            Voltar
                        </div>
                        <div className="" style={ { flexGrow: 1 } } />
                    </>
                    : null
                }
                <button className="btn_save">Salvar dados editados ({idPage})</button>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados do login</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setUserName(e.target.value) } } value={ userName } maxLength={ 140 } required={ true } />
                        <span className="name_input">Nome</span>
                    </div>
                    <div className="div_input">
                        <input type="email" maxLength={ 60 } onChange={ (e)=>{ setUserEmail(e.target.value.replaceAll(' ', '')) } } value={ userEmail } required={ true } />
                        <span className="name_input">E-mail</span>
                    </div>
                    <div className="div_input">
                        <input type="password" onChange={ (e)=>{ setUserPassword(e.target.value.replaceAll(' ', '')) } } value={ userPassword } required={ idPage == 0 ? true : false } />
                        <span className="name_input">Senha</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados da empresa</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setNameFantasy(e.target.value) } } value={ nameFantasy } maxLength={ 140 } required={ true } />
                        <span className="name_input">Nome Fantasia</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="cnpj" placeholder="000.000.000/0001-00" maxLength={ 20 } onChange={ (e)=>{ setCnpj(e.target.value) } } value={ cnpjMask(cnpj) } />
                        <span className="name_input">CNPJ</span>
                    </div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setSocialReason(e.target.value) } } value={ socialReason } />
                        <span className="name_input">Razão Social</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="cnpj" placeholder="000.000.000/0001-00" maxLength={ 20 } onChange={ (e)=>{ setStateRegistration(e.target.value) } } value={ cnpjMask(stateRegistration) } />
                        <span className="name_input">Inscrição Estadual</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados pessoais</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength={ 140 } />
                        <span className="name_input">Nome</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="cnpj" placeholder="000.000.000/0001-00" maxLength={ 20 } onChange={ (e)=>{ setCau(e.target.value) } } value={ cnpjMask(cau) } />
                        <span className="name_input">Cau</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Endereço</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input">
                        <input type="text" className="cep" placeholder="00000-000" maxLength={ 9 } onChange={ (e)=>{ setCep(e.target.value.replaceAll(' ', '')) } } value={ cepMask(cep) } />
                        <span className="name_input">CEP</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="uf" maxLength={ 2 } onChange={ (e)=>{ setUf(e.target.value.replaceAll(' ', '')) } } value={ uf } />
                        <span className="name_input">UF</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } />
                        <span className="name_input">Cidade</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } />
                        <span className="name_input">Bairro</span>
                    </div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setRoad(e.target.value) } } value={ road } />
                        <span className="name_input">Rua</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="number" onChange={ (e)=>{ setNumber(e.target.value.replaceAll(' ', '')) } } value={ number } />
                        <span className="name_input">Número</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Contato</div>
                    <div className="btn_save" onClick={ ()=>{ setListContact([...listContact, { "id": 0, "name": "", "email": "", "contact": "", "whatsapp": "" }]) } }>Adicionar novo contato</div>
                </div>
                
                {
                    listContact.length > 0 ?
                        listContact.map((elem, index)=>{
                            return(
                                <div className="list_input_data div_line_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('name', index, e.target.value.replaceAll(' ', '')) } } value={ elem.name } maxLength={ 140 } />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="email" className="" onChange={ (e)=>{ HandleData('email', index, e.target.value.replaceAll(' ', '')) } } value={ elem.email } maxLength={ 60 } />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('contact', index, e.target.value) } } value={ elem.contact } maxLength={ 40 } />
                                        <span className="name_input">Contato</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('whatsapp', index, e.target.value) } } value={ elem.whatsapp } maxLength={ 30 } />
                                        <span className="name_input">Whatsapp</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ Delete(index, elem.id, elem.name) } }>
                                        <Svg_Delete className="icons" color="#f00000" />
                                    </div>
                                </div>
                            )
                        })
                    :
                    <div className="no_data">Nenhum contato encontrado...</div>
                }
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Redes Sociais / Site</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setFacebook(e.target.value.replaceAll(' ', '')) } } value={ facebook } />
                        <span className="name_input">Facebook</span>
                    </div>
                    {
                        facebook !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_facebook" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ facebook } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_facebook" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setInstagram(e.target.value.replaceAll(' ', '')) } } value={ instagram } />
                        <span className="name_input">Instagram</span>
                    </div>
                    {
                        instagram !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_instagram" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ instagram } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_instagram" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setTiktok(e.target.value.replaceAll(' ', '')) } } value={ tiktok } />
                        <span className="name_input">TikTok</span>
                    </div>
                    {
                        tiktok !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_tiktok" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ tiktok } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_tiktok" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setPinterest(e.target.value.replaceAll(' ', '')) } } value={ pinterest } />
                        <span className="name_input">Pinterest</span>
                    </div>
                    {
                        pinterest !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_pinterest" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ pinterest } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_pinterest" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setSite(e.target.value.replaceAll(' ', '')) } } value={ site } />
                        <span className="name_input">Site</span>
                    </div>
                    {
                        site !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_site" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ site } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_site" />
                        </>
                        : null
                    }
                </div>
            </div>
        </form>
    )
}