import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListDataSingle } from 'interface/Data';
import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw } from 'fixedData';
import { SetUserData } from 'interface/Users';
import { SetListPag } from 'interface/Page';

// Alt Status data
export function Reg_AltStatus(userId, type, id, origin, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);

    console.log(type, id, origin);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        if(origin == 'architects'){
            SetListDataSingle('architects', response.data['architects']);
            SetListDataSingle('project', response.data['project']);
        }else if(origin != 'access_dash'){
            SetListDataSingle('access_dash', response.data['access_dash']);
            SetListDataSingle(origin, response.data[origin]);
        }else {
            SetListDataSingle(origin, response.data[origin]);
        }
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url: process.env.REACT_APP_PHP + 'inc/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        switch (response.data) {
            case "Email enviado com sucesso":
                CallbackSuccess();
                break;
            case "Email não enviado":
                CallbackError_Send();
                break;
            case "E-mail não encontrado":
                CallbackError_NotEmail();
                break;
            case "Erro ao enviar mensagem":
                CallbackError();
                break;
            default:
                CallbackError();
                break;
        }

    }).catch((error)=>{

        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url : process.env.REACT_APP_PHP + 'inc/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        if(response.data == "conectado"){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Save crop
export function Reg_CropImg(origin, userId, idGiven, idPage, type, file, positionX, positionY, width, height, showWidth, widthReal, CallbackSuccess, CallbackError){
    const data = new FormData();
    
    data.append('type_page', origin);
    data.append('edit_data', 'add_file');

    data.append('userId', userId);
    data.append('idGiven', idGiven);
    data.append('idPage', idPage);
    data.append('type_file', type);
    data.append('file', file);
    data.append('positionX', positionX);
    data.append('positionY', positionY);
    data.append('width', width);
    data.append('height', height);
    data.append('showWidth', showWidth);
    data.append('widthReal', widthReal);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        
        if(type == 'file'){
            if(idGiven == userId){
                SetUserData('file', response.data.file);
            }
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Shopkeepers
export function Reg_Shopkeepers(userId, origin, idGiven, idPage, name, cnpj, socialReason, stateRegistration, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, tiktok, pinterest, site, userName, userEmail, userPassword, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'shopkeepers');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idGiven', idGiven);
    data.append('idPage', idPage);

    data.append('name', name);
    data.append('cnpj', cnpj);
    data.append('social_reason', socialReason);
    data.append('state_registration', stateRegistration);

    data.append('road', road);
    data.append('number', number);
    data.append('neighborhood', neighborhood);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);

    data.append('facebook', facebook);
    data.append('instagram', instagram);
    data.append('tiktok', tiktok);
    data.append('pinterest', pinterest);
    data.append('site', site);
    
    data.append('userName', userName);
    data.append('userEmail', userEmail);
    data.append('userPassword', userPassword);

    listContact.forEach((elem, index) => {
        data.append('contact_id[]', elem.id);
        data.append('contact_name[]', elem.name);
        data.append('contact_email[]', elem.email);
        data.append('contact_contact[]', elem.contact);
        data.append('contact_whatsapp[]', elem.whatsapp);
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/shopkeepers.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListDataSingle('shopkeepers', response.data['shopkeepers']);
        SetListDataSingle('access_dash', response.data['access_dash']);
        
        if(origin == ""){            
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 2);
                        
            cookies.set(typeCookie, 'dashboard', { path: '/', expires: add3Days }, cookiesRegister);
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);
            if(response.data.pass !=0){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }

            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }else {
            SetListPag('currentPageId', response.data.idPage);
        }
        
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Architect
export function Reg_Architects(userId, origin, idGiven, idPage, nameFantasy, cnpj, socialReason, stateRegistration, name, cau, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, tiktok, pinterest, site, userName, userEmail, userPassword, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'architects');
    data.append('edit_data', 'edit_or_register');

    data.append('typePageRegister', origin);
    data.append('userId', userId);
    data.append('idGiven', idGiven);
    data.append('idPage', idPage);

    data.append('nameFantasy', nameFantasy);
    data.append('cnpj', cnpj);
    data.append('social_reason', socialReason);
    data.append('state_registration', stateRegistration);

    data.append('name', name);
    data.append('cau', cau);

    data.append('road', road);
    data.append('number', number);
    data.append('neighborhood', neighborhood);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);

    data.append('facebook', facebook);
    data.append('instagram', instagram);
    data.append('tiktok', tiktok);
    data.append('pinterest', pinterest);
    data.append('site', site);
    
    data.append('userName', userName);
    data.append('userEmail', userEmail);
    data.append('userPassword', userPassword);

    listContact.forEach((elem, index) => {
        data.append('contact_id[]', elem.id);
        data.append('contact_name[]', elem.name);
        data.append('contact_email[]', elem.email);
        data.append('contact_contact[]', elem.contact);
        data.append('contact_whatsapp[]', elem.whatsapp);
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/architects.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);        
        if(origin == ""){            
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 2);
                        
            cookies.set(typeCookie, 'dashboard', { path: '/', expires: add3Days }, cookiesRegister);
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);
            if(response.data.pass !=0){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }

            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }else {
            SetListPag('currentPageId', response.data.idPage);
        }
        SetListDataSingle('access_dash', response.data['access_dash']);
        SetListDataSingle('architects', response.data['architects']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Architect - Details
export function Reg_ArchitectsDetails(userId, idPage, cover, title, text, listGallery, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'architects');
    data.append('edit_data', 'project');

    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('cover', cover);
    data.append('title', title);
    data.append('text', text);

    listGallery.forEach((elem, index) => {
        data.append('gallery_id[]', elem.id);
        data.append('gallery_type[]', elem.type);
        data.append('gallery_name[]', elem.name);
        data.append('gallery_file_' + index, elem.file);
        data.append('gallery_link[]', elem.link);
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/architects.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListDataSingle('project', response.data['project']);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Images
export function Reg_ImagesOrPdf(userId, origin, type, idPage, title, file, CallbackSuccess, CallbackError){
    const data = new FormData();
    
    data.append('type_page', 'images');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('origin', origin);
    data.append('type', type);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('file', file);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Assistants
export function Reg_Assistants(userId, idGiven, idPage, cover, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, file, filePositionX, filePositionY, fileWidth, fileHeight, fileShowWidth, fileWidthReal, name, cpf, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, page, userName, userEmail, userPassword, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'assistants');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idGiven', idGiven);
    data.append('idPage', idPage);

    data.append('cover', cover);
    data.append('coverPositionX', coverPositionX);
    data.append('coverPositionY', coverPositionY);
    data.append('coverWidth', coverWidth);
    data.append('coverHeight', coverHeight);
    data.append('coverShowWidth', coverShowWidth);
    data.append('coverWidthReal', coverWidthReal);
    
    data.append('file', file);
    data.append('filePositionX', filePositionX);
    data.append('filePositionY', filePositionY);
    data.append('fileWidth', fileWidth);
    data.append('fileHeight', fileHeight);
    data.append('fileShowWidth', fileShowWidth);
    data.append('fileWidthReal', fileWidthReal);

    data.append('name', name);
    data.append('cpf', cpf);

    data.append('road', road);
    data.append('number', number);
    data.append('neighborhood', neighborhood);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);

    data.append('facebook', facebook);
    data.append('instagram', instagram);
    
    data.append('userName', userName);
    data.append('userEmail', userEmail);
    data.append('userPassword', userPassword);

    listContact.forEach((elem, index) => {
        data.append('contact_id[]', elem.id);
        data.append('contact_name[]', elem.name);
        data.append('contact_email[]', elem.email);
        data.append('contact_contact[]', elem.contact);
        data.append('contact_whatsapp[]', elem.whatsapp);
    });

    page.forEach((elem, index) => {
        data.append('page_index[]', index);
        data.append('page_id[]', elem.id);
        data.append('page_name[]', elem.name);
        data.append('page_page[]', elem.page);
        data.append('page_type[]', elem.type);
        data.append('page_order[]', elem.order);
        
        elem.list_sub.map((elem_1, index_1)=>{
            data.append('sub_page_index[]', index);
            data.append('sub_page_id[]', elem_1.id);
            data.append('sub_page_name[]', elem_1.name);
            data.append('sub_page_page[]', elem_1.page);
            data.append('sub_page_type[]', elem_1.type);
            data.append('sub_page_order[]', elem_1.order);
        })
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/assistants.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListDataSingle('access_dash', response.data['access_dash']);
        SetListDataSingle('assistants', response.data['assistants']);
        SetListPag('currentPageId', response.data.idPage);
        
        if(file == ''){
            if(userId == idPage){
                SetUserData('file', response.data.file);
            }
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Assistants
export function Reg_UserAdministrator(userId, idPage, cover, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, file, filePositionX, filePositionY, fileWidth, fileHeight, fileShowWidth, fileWidthReal, name, email, password, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('cover', cover);
    data.append('coverPositionX', coverPositionX);
    data.append('coverPositionY', coverPositionY);
    data.append('coverWidth', coverWidth);
    data.append('coverHeight', coverHeight);
    data.append('coverShowWidth', coverShowWidth);
    data.append('coverWidthReal', coverWidthReal);
    
    data.append('file', file);
    data.append('filePositionX', filePositionX);
    data.append('filePositionY', filePositionY);
    data.append('fileWidth', fileWidth);
    data.append('fileHeight', fileHeight);
    data.append('fileShowWidth', fileShowWidth);
    data.append('fileWidthReal', fileWidthReal);

    data.append('name', name);
    data.append('email', email);
    data.append('password', password);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/access_dash.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('access_dash', response.data['access_dash']);
        
        if(userId == idPage){                         
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 2);
                        
            cookies.set(typeCookie, 'dashboard', { path: '/', expires: add3Days }, cookiesRegister);
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);
            if(response.data.pass !=0){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }

            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
            if(response.data.file !=''){
                SetUserData('file', response.data.file);
            }
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}