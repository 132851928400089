import { useState, useEffect } from "react";

import './Details.css';

import { Svg_Arrow } from "components/Svg";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

export default function Page_Administrator_ProductCatalog_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('assistants'));

    function InitialData(type){                
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        // Reg_Assistants(userId, idGiven, GetUserData('nivel') == 2 ? GetUserData('idPage') : idPage, cover, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, file, filePositionX, filePositionY, fileWidth, fileHeight, fileShowWidth, fileWidthReal, name, cpf, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, page, userName, userEmail, userPassword, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('product_catalog', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('product_catalog', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('product_catalog'));
    }, [dataPage]);

    return(
        <form className="Page_Administrator_ProductCatalog_Details width_page" onSubmit={ SaveData }>
            
            <div className="div_btn_save">
                <div className="topic_return_admin" onClick={ ()=>{ props.ReturnPage('product_catalog') } }>
                    <Svg_Arrow className="icons" color={ colorIcon } />
                    Voltar
                </div>
                <div className="" style={ { flexGrow: 1 } } />
                <button className="btn_save">Salvar dados editados</button>
            </div>
        </form>
    )
}