import { useState, useEffect } from "react";

import './Details.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Svg_Arrow, Svg_Edit, Svg_Logo_Abbreviated } from "components/Svg";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { Reg_UserAdministrator } from "services/RegisterData";

export default function Page_Administrator_UserAdministrator_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_dash'));

    const [ cover, setCover ] = useState(InitialData('cover'));
    const [ coverStatus, setCoverStatus ] = useState(false);
    const [ coverPositionX, setCoverPositionX ] = useState(0);
    const [ coverPositionY, setCoverPositionY ] = useState(0);
    const [ coverWidth, setCoverWidth ] = useState(0);
    const [ coverHeight, setCoverHeight ] = useState(0);
    const [ coverShowWidth, setCoverShowWidth ] = useState(0);
    const [ coverWidthReal, setCoverWidthReal ] = useState(0);

    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ filePositionX, setFilePositionX ] = useState(0);
    const [ filePositionY, setFilePositionY ] = useState(0);
    const [ fileWidth, setFileWidth ] = useState(0);
    const [ fileHeight, setFileHeight ] = useState(0);
    const [ fileShowWidth, setFileShowWidth ] = useState(0);
    const [ fileWidthReal, setFileWidthReal ] = useState(0);

    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ password, setPassword ] = useState('');

    function InitialData(type){
        if(GetDataPage('access_dash').find(item => item.id == idPage)){
            const newData = GetDataPage('access_dash').find(item => item.id == idPage);
            return newData[type];
        }
        return '';
    }

    function RegisterCropData(img){
        if(img.file){
            // file
            if(img.type == 'file'){
                setFileStatus(true);
                setFile(img.file);
                setFilePositionX(img.positionX);
                setFilePositionY(img.positionY);
                setFileWidth(img.width);
                setFileHeight(img.height);
                setFileShowWidth(img.showWidth);
                setFileWidthReal(img.widthReal);
            }
            // cover
            if(img.type == 'cover'){
                setCoverStatus(true);
                setCover(img.file);
                setCoverPositionX(img.positionX);
                setCoverPositionY(img.positionY);
                setCoverWidth(img.width);
                setCoverHeight(img.height);
                setCoverShowWidth(img.showWidth);
                setCoverWidthReal(img.widthReal);
            }
        }
    }

    function CropImg(type, value, index){
        let proportionX = 0;
        let proportionY = 0;

        // file
        if(type == 'file'){
            proportionX = 16;
            proportionY = 16;
        // cover 
        }else if(type == 'cover'){
            proportionX = 16;
            proportionY = 3;
        }     
        SetModalData('PhotoCrop', { "type": type, "index": index, "showImg": URL.createObjectURL(value), "file": value, "RegisterCropData": RegisterCropData, "proportionX": proportionX, "proportionY": proportionY });   
        SetModalState('PhotoCrop', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_UserAdministrator(userId, idPage, cover, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, file, filePositionX, filePositionY, fileWidth, fileHeight, fileShowWidth, fileWidthReal, name, email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('access_dash', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('access_dash', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('access_dash'));

        setCover(InitialData('cover'));
        setCoverStatus(false);
        setCoverPositionX(0);
        setCoverPositionY(0);
        setCoverWidth(0);
        setCoverHeight(0);
        setCoverShowWidth(0);
        setCoverWidthReal(0);

        setFile(InitialData('file'));
        setFileStatus(false);
        setFilePositionX(0);
        setFilePositionY(0);
        setFileWidth(0);
        setFileHeight(0);
        setFileShowWidth(0);
        setFileWidthReal(0);

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setPassword('');
    }, [dataPage]);

    return(
        <form className="Page_Administrator_Assistants_Details width_page" onSubmit={ SaveData }>
            <div className="div_file">
                {
                    coverStatus ?
                    <div className={ cover ? "show_cover" : "show_cover not_cover" } style={ { backgroundImage: 'url("' + URL.createObjectURL(cover) + '")' } }>
                        {
                            cover ? null : "Capa não encontrada"
                        }
                        <label>
                            <div className="alt_cover">
                                <input type="file" onChange={ (e)=>{ CropImg('cover', e.target.files[0], 0) } } accept="image/*" />
                                Editar capa
                            </div>
                        </label>
                    </div> :
                    <div className={ cover ? "show_cover" : "show_cover not_cover" } style={ { backgroundImage: 'url("' + cover + '")' } }>
                        {
                            cover ? null : "Capa não encontrada"
                        }
                        <label>
                            <div className="alt_cover">
                                <input type="file" onChange={ (e)=>{ CropImg('cover', e.target.files[0], 0) } } accept="image/*" />
                                Editar capa
                            </div>
                        </label>
                    </div>
                }

                <div className="show_img">
                    <div className="file_user">
                        {
                            file == "" ? <Svg_Logo_Abbreviated className="icons" /> :
                            <img al="img" src={ fileStatus ? URL.createObjectURL(file) : file } className="icons" />
                        }                        
                    </div>
                    <div className="alt_file" data-tooltip-id="alt_file_user" data-tooltip-content="Alterar sua foto" data-tooltip-place="top">
                        <label>
                            <input type="file" onChange={ (e)=>{ CropImg('file', e.target.files[0], 0) } } accept="image/*" />
                            <Svg_Edit className="icons" color={ colorIcon } />
                        </label>
                    </div>
                    <Tooltip id="alt_file_user" />
                </div>
            </div>
            
            <div className="div_btn_save">
                <div className="topic_return_admin" onClick={ ()=>{ props.ReturnPage('index') } }>
                    <Svg_Arrow className="icons" color={ colorIcon } />
                    Voltar
                </div>
                <div className="" style={ { flexGrow: 1 } } />
                <button className="btn_save">Salvar dados editados</button>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados do login</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength={ 140 } />
                        <span className="name_input">Nome</span>
                    </div>
                    <div className="div_input">
                        <input type="email" maxLength={ 60 } onChange={ (e)=>{ setEmail(e.target.value.replaceAll(' ', '')) } } value={ email } />
                        <span className="name_input">E-mail</span>
                    </div>
                    <div className="div_input">
                        <input type="password" onChange={ (e)=>{ setPassword(e.target.value.replaceAll(' ', '')) } } value={ password } required={ idPage == 0 ? true : false } />
                        <span className="name_input">Senha</span>
                    </div>
                </div>
            </div>
        </form>
    )
}