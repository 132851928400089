import React, { useState, useEffect } from "react";

import './RecoverPass.css';

import { CheckedEmail } from "services/RegisterData";

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Svg_Close } from "components/Svg";

export default function PopUP_RecoverPass(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ email, setEmail ] = useState('');
    const [ msgError, setMsgError ] = useState('');
    const [ typeReturn, setTypeReturn ] = useState(0);
    
    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);

        CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail);        
    }

    function CallbackSuccess(){
        setTypeReturn(1);
        props.setLoading(false);
        setMsgError('Foi enviado um link para seu e-mail com instruções para criar uma nova senha.');
    }

    function CallbackError(){
        setTypeReturn(4);
        props.setLoading(false);
        setMsgError('Erro ao enviar e-mail, tente novamente!');
    }

    function CallbackError_Send(){
        setTypeReturn(2);
        props.setLoading(false);
        setMsgError('E-mail não enviado, tente novamente!');
    }

    function CallbackError_NotEmail(){
        setTypeReturn(3);
        props.setLoading(false);
        setMsgError('E-mail não encontrado!');
    }

    function ClosePopUp(){
        SetModalState('RecoverEmail', false);
    }

    useEffect(()=>{
        RegisterModalData('RecoverEmail', setModaldata);
        RegisterModalObserver('RecoverEmail', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setEmail('');
            setTypeReturn(0);
            setMsgError('');
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form onSubmit={ SaveData }>
                <div className="PopUp PopUpCenter">
                    <div className="all recover_pass">
                        <div className="div_data type_div">
                            <div className="title">
                                Recuperar senha
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <Svg_Close color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {
                                    typeReturn == 0 ?
                                    <>
                                        <div className="title_stage">
                                            Digite seu e-mail abaixo
                                        </div>                                
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="email" className="space_width" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="140" />
                                            </div>
                                        </div>
                                        <div className="div_btn">
                                            <button type="submit" className="btn_save">Salvar</button>
                                        </div>
                                    </> :
                                    <>
                                        <div className="msg_return">
                                            { msgError }
                                        </div>
                                        {
                                            typeReturn == 0 || typeReturn == 1 ? null :
                                            <div className="btn_save exit" onClick={ ()=>{ setMsgError(''); setTypeReturn(0) } }>Alterar e-mail</div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
