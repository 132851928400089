import { useState, useEffect } from "react";

import { Svg_Arrow, Svg_Logo, Svg_User } from "components/Svg";

import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import Page_Downloads from "pages/Downloads";
import Page_Architects_Profile from "pages/Architect/Profile";
import Page_Shopkeepers_Profile from "pages/Shopkeepers/Profile";
import Page_Administrator_Profile from "pages/Administrator/Profile";
import Page_Administrator_Assistants from "pages/Administrator/Assistants";
import Page_Administrator_ProductCatalog from "pages/Administrator/ProductCatalog";
import Page_Administrator_UserAdministrator from "pages/Administrator/UserAdministrator";
import Page_Administrator_Assistants_Details from "pages/Administrator/Assistants/Details";
import Page_Administrator_ApprovalRegistrations from "pages/Administrator/ApprovalRegistrations";
import Page_Administrator_ProductCatalog_Details from "pages/Administrator/ProductCatalog/Details";
import Page_Administrator_UserAdministrator_Details from "pages/Administrator/UserAdministrator/Details";

export default function Contents_Administrator(props){

    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));

    const [ listOptDownload, setListOptDownload ] = useState([
        { "name": "Imagens", "page": "images" },
        { "name": "Catálogos", "page": "catalogs" },
        { "name": "Blocos 3D", "page": "3d_blocks" },
        { "name": "Manuais", "page": "manuals" },
        { "name": "Certificados", "page": "certificates" }
    ]);

    function ReturnPage(value){
        SetListPag('currentPage', value);
        SetListPag('currentPageId', 'remuve');
    }

    function CurrentPage(){
        switch (currentPage) {
            case "index":
                return <Page_Administrator_UserAdministrator name="Dashboard" origin="access_dash" setLoading={ props.setLoading } />;
            case "user_administrator_details":
                return <Page_Administrator_UserAdministrator_Details name="Dashboard" origin="access_dash" ReturnPage={ ReturnPage } setLoading={ props.setLoading } />;

            case "architects":
                return <Page_Administrator_Profile name="Arquitetos" origin="architects" setLoading={ props.setLoading } />;
            case "administrator_architects_details":
                return <Page_Architects_Profile origin="admin" idPage={ idPage } ReturnPage={ ReturnPage } setLoading={ props.setLoading } />;

            case "shopkeepers":
                return <Page_Administrator_Profile name="Lojistas" origin="shopkeepers" setLoading={ props.setLoading } />;
            case "administrator_shopkeepers_details":
                return <Page_Shopkeepers_Profile origin="admin" idPage={ idPage } ReturnPage={ ReturnPage } setLoading={ props.setLoading } />;

            case "assistants":
                return <Page_Administrator_Assistants name="" origin="assistants" setLoading={ props.setLoading } />;
            case "assistants_details":
                return <Page_Administrator_Assistants_Details name="" origin="assistants" ReturnPage={ ReturnPage } setLoading={ props.setLoading } />;

            case "images":
                return <Page_Downloads type="block" name="Imagens" origin="images" setLoading={ props.setLoading } />;

            case "catalogs":
                return <Page_Downloads type="block" name="Catálogos" origin="catalogs" setLoading={ props.setLoading } />;

            case "3d_blocks":
                return <Page_Downloads type="block" name="3D Blocos" origin="3D_blocks" setLoading={ props.setLoading } />;

            case "manuals":
                return <Page_Downloads type="block" name="Manuais" origin="manuals" setLoading={ props.setLoading } />;

            case "certificates":
                return <Page_Downloads type="block" name="Certificados" origin="certificates" setLoading={ props.setLoading } />;
            
            case "product_catalog":
                return <Page_Administrator_ProductCatalog type="block" setLoading={ props.setLoading } />;
            case "product_catalog_details":
                return <Page_Administrator_ProductCatalog_Details type="block" ReturnPage={ ReturnPage } setLoading={ props.setLoading } />;

            case "approval_of_registrations":
                return <Page_Administrator_ApprovalRegistrations type="block" setLoading={ props.setLoading } />;
        }
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);
        RegisterListPag('currentPageId', setIdPage);
        return ()=>{
            UnRegisterListPag('currentPage', setCurrentPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    return(
        <div className="Contents">
            <div className="div_menu">
                <div className="list_menu">
                    <div className="div_logo">
                        <Svg_Logo className="logo" color="#ffffff" />
                    </div>
                    <div className="opt_menu show_data_user">
                        <div className="div_data_img">
                            {
                                props.userFile == "" ? 
                                <Svg_User className="img_user" color="#ffffff" /> :
                                <img alt="user" src={ props.userFile } className="img_user" />
                            }
                        </div>
                        <div className="div_data_user">
                            <div className="hello">Olá,</div>
                            <div className="name_user">{ props.userName.split(' ')[0] + " " + props.userName.split(' ')[1] }</div>
                        </div>
                    </div>
                    <div className="opt_menu show_opt_menu">
                        <div className={ currentPage == "index" || currentPage == "user_administrator_details" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('index') } }>
                            Dashboard
                        </div>
                        <div className={ currentPage == "architects" || currentPage == "administrator_architects_details" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('architects') } }>
                            Arquitetos
                        </div>
                        <div className={ currentPage == "shopkeepers" || currentPage == "administrator_shopkeepers_details" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('shopkeepers') } }>
                            Lojistas
                        </div>
                        <div className={ currentPage == "assistants" || currentPage == "assistants_details" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('assistants') } }>
                            Assistentes
                        </div>
                        <div className="name_menu">
                            <div className="submenu">Downloads</div>
                            {
                                listOptDownload.map((elem, index)=>{
                                    return(
                                        <div className={ currentPage == elem.page ? "submenu menu_active" : "submenu" } key={ index } onClick={ ()=>{ props.CkickPage(elem.page) } }>
                                            <Svg_Arrow className="icons" color="#ffffff" />
                                            { elem.name }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={ currentPage == "product_catalog" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('product_catalog') } }>
                            Catálogo de Produtos
                        </div>
                        <div className={ currentPage == "approval_of_registrations" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('approval_of_registrations') } }>
                            Aprovação de Cadastros
                        </div>
                        <div className="name_menu exit" onClick={ ()=>{ props.Logout() } }>Sair</div>
                    </div>
                </div>
            </div>
            <div className="div_options">
                { CurrentPage() }
            </div>
        </div>
    )
}