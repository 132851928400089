import React, { useState, useEffect } from "react";

import './EditOrNewDownloads.css';

import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Svg_Close } from "components/Svg";

import { Reg_ImagesOrPdf } from "services/RegisterData";

export default function PopUP_EditOrNewDownloads(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ title, setTitle ] = useState('');
    const [ file, setFile ] = useState('');

    function SaveData(){
        props.setLoading(true);
        Reg_ImagesOrPdf(userId, modalData.origin, modalData.type, modalData.id, title, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
        
        ClosePopUp();
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setTitle('');
        setFile('');
        SetModalState('EditOrNewDownloads', false);
    }

    useEffect(()=>{        
        RegisterModalData('EditOrNewDownloads', setModaldata);
        RegisterModalObserver('EditOrNewDownloads', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            if(modalData.id !=0){
                setTitle(modalData.title);
                setFile(modalData.file);
            }
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all edit_or_new_downloads">
                    <div className="div_data type_div">
                        <div className="title">
                            {
                                modalData.id == 0 ? "Adicionar " + modalData.name : "Editar dados"
                            }
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="space_width" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                                    <span className="name_input">Título</span>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="space_width" onChange={ (e)=>{ setFile(e.target.value) } } value={ file } maxLength="140" />
                                    <span className="name_input">Link google drive</span>
                                </div>
                            </div>

                            <div className="register_data button_edit_img" onClick={ ()=>{ SaveData() } }>
                                Salvar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
