import { useState, useEffect } from "react";

import './Details.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Svg_Arrow, Svg_Delete, Svg_Edit, Svg_Link, Svg_Logo_Abbreviated } from "components/Svg";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { cepMask, cpfMask } from "services/Mask";
import { Reg_Assistants, Reg_CropImg } from "services/RegisterData";

export default function Page_Administrator_Assistants_Details(props){
    
    const [ listPage, setListPage ] = useState([
        { "id": 0, "name": "Arquitetos", "page": "architects", "type": "hide_page", "order": 0, "list_sub": [] },
        { "id": 0, "name": "Lojistas", "page": "shopkeepers", "type": "hide_page", "order": 1, "list_sub": [] },
        { "id": 0, "name": "Downloads", "page": "", "type": "", "order": 2, "list_sub": [
            { "id": 0, "name": "Imagens", "page": "downl_images", "type": "hide_page", "order": 0 },
            { "id": 0, "name": "Catálogos", "page": "downl_catalogs", "type": "hide_page", "order": 1 },
            { "id": 0, "name": "Blocos 3D", "page": "downl_3d_blocks", "type": "hide_page", "order": 2 },
            { "id": 0, "name": "Manuais", "page": "downl_manuals", "type": "hide_page", "order": 3 },
            { "id": 0, "name": "Certificados", "page": "downl_certificates", "type": "hide_page", "order": 4 }
        ] },
        { "id": 0, "name": "Catálogo de Produtos", "page": "product_catalog", "type": "hide_page", "order": 3, "list_sub": [] },
        { "id": 0, "name": "Aprovação de Cadastros", "page": "approval_of_registrations", "type": "hide_page", "order": 4, "list_sub": [] }
    ]);

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('assistants'));

    const [ cover, setCover ] = useState(InitialData('cover'));
    const [ coverStatus, setCoverStatus ] = useState(false);
    const [ coverPositionX, setCoverPositionX ] = useState(0);
    const [ coverPositionY, setCoverPositionY ] = useState(0);
    const [ coverWidth, setCoverWidth ] = useState(0);
    const [ coverHeight, setCoverHeight ] = useState(0);
    const [ coverShowWidth, setCoverShowWidth ] = useState(0);
    const [ coverWidthReal, setCoverWidthReal ] = useState(0);

    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ filePositionX, setFilePositionX ] = useState(0);
    const [ filePositionY, setFilePositionY ] = useState(0);
    const [ fileWidth, setFileWidth ] = useState(0);
    const [ fileHeight, setFileHeight ] = useState(0);
    const [ fileShowWidth, setFileShowWidth ] = useState(0);
    const [ fileWidthReal, setFileWidthReal ] = useState(0);
   
    const [ idGiven, setIdGiven ] = useState(InitialData('id_given'));
    const [ name, setName ] = useState(InitialData('name'));
    const [ cpf, setCpf ] = useState(InitialData('cpf'));

    const [ road, setRoad ] = useState(InitialData('road'));
    const [ number, setNumber ] = useState(InitialData('number'));
    const [ neighborhood, setNeighborhood ] = useState(InitialData('neighborhood'));
    const [ cep, setCep ] = useState(InitialData('cep'));
    const [ city, setCity ] = useState(InitialData('city'));
    const [ uf, setUf ] = useState(InitialData('uf'));
    const [ listContact, setListContact ] = useState(InitialData('list_contact'));

    const [ facebook, setFacebook ] = useState(InitialData('facebook'));
    const [ instagram, setInstagram ] = useState(InitialData('instagram'));
    const [ page, setPage ] = useState(InitialData('page'));

    const [ userName, setUserName ] = useState(DataLogin('name'));
    const [ userEmail, setUserEmail ] = useState(DataLogin('email'));
    const [ userPassword, setUserPassword ] = useState('');

    function InitialData(type){
        if(GetUserData('nivel') == 2){
            const newData = GetDataPage('assistants').find(item => item.id === GetUserData('idPage'));
            if(newData){
                return newData[type];
            }
            if(type === 'id_given'){
                return 0;
            }
            if(type === 'page'){
                return listPage;
            }
            if(type === 'list_contact'){
                return [];
            }

        }else {
            const newData = GetDataPage('assistants').find(item => item.id === idPage);
            if(newData){
                return newData[type];
            }
            if(type === 'id_given'){
                return 0;
            }
            if(type === 'page'){
                return listPage;
            }
            if(type === 'list_contact'){
                return [];
            }
        }
        
        return '';
    }

    function DataLogin(type){
        const newData = GetDataPage('access_dash').find(item => item.id === idGiven);
        if(newData){
            return newData[type];
        }
        return '';
    }

    function RegisterCropData(img){
        props.setLoading(true);
        Reg_CropImg('assistants', userId, idGiven, GetUserData('nivel') == 2 ? GetUserData('idPage') : idPage, img.type, img.file, img.positionX, img.positionY, img.width, img.height, img.showWidth, img.widthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CropImg(type, value, index){
        let proportionX = 0;
        let proportionY = 0;

        // file
        if(type == 'file'){
            proportionX = 16;
            proportionY = 16;
        // cover 
        }else if(type == 'cover'){
            proportionX = 16;
            proportionY = 3;
        }     
        SetModalData('PhotoCrop', { "type": type, "index": index, "showImg": URL.createObjectURL(value), "file": value, "RegisterCropData": RegisterCropData, "proportionX": proportionX, "proportionY": proportionY });   
        SetModalState('PhotoCrop', true);
    }

    /* contact */
    function HandleData(type, index, value){
        const newData = [...listContact];
        newData[index][type] = value;
        setListContact(newData);
    }
    function Delete(index, id, value){
        if(id == 0){
            const newData = [...listContact];
            newData.splice(index, 1);
            setListContact(newData);
        }else {
            SetModalData('Confirmation', { "type": "delete_contact", "id": id, "origin": "assistants", "name": value });
            SetModalState('Confirmation', true);
        }
    }
    /* end */

    function HandleDataPage(type, index, value, index_1){
        const newData = [...page];        
        if(index_1 !='only'){
            console.log(index_1);
            newData[index]['list_sub'][index_1][type] = value;
        }else {
            newData[index][type] = value;
        }
        setPage(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Assistants(userId, idGiven, GetUserData('nivel') == 2 ? GetUserData('idPage') : idPage, cover, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, file, filePositionX, filePositionY, fileWidth, fileHeight, fileShowWidth, fileWidthReal, name, cpf, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, page, userName, userEmail, userPassword, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('assistants', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('assistants', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('assistants'));

        setCover(InitialData('cover'));
        setCoverStatus(false);
        setCoverPositionX(0);
        setCoverPositionY(0);
        setCoverWidth(0);
        setCoverHeight(0);
        setCoverShowWidth(0);
        setCoverWidthReal(0);

        setFile(InitialData('file'));
        setFileStatus(false);
        setFilePositionX(0);
        setFilePositionY(0);
        setFileWidth(0);
        setFileHeight(0);
        setFileShowWidth(0);
        setFileWidthReal(0);
    
        setIdGiven(InitialData('id_given'));
        setName(InitialData('name'));
        setCpf(InitialData('cpf'));

        setRoad(InitialData('road'));
        setNumber(InitialData('number'));
        setNeighborhood(InitialData('neighborhood'));
        setCep(InitialData('cep'));
        setCity(InitialData('city'));
        setUf(InitialData('uf'));
        setListContact(InitialData('list_contact'));

        setFacebook(InitialData('facebook'));
        setInstagram(InitialData('instagram'));
        setPage(InitialData('page'));

        setUserName(DataLogin('name'));
        setUserEmail(DataLogin('email'));
        setUserPassword('');
    }, [dataPage]);

    return(
        <form className="Page_Administrator_Assistants_Details width_page" onSubmit={ SaveData }>
            {
                idPage !=0 || GetUserData('nivel') == 2 ?
                <div className="div_file">
                    {
                        coverStatus ?
                        <div className={ cover ? "show_cover" : "show_cover not_cover" } style={ { backgroundImage: 'url("' + URL.createObjectURL(cover) + '")' } }>
                            {
                                cover ? null : "Capa não encontrada"
                            }
                            <label>
                                <div className="alt_cover">
                                    <input type="file" onChange={ (e)=>{ CropImg('cover', e.target.files[0], 0) } } accept="image/*" />
                                    Editar capa
                                </div>
                            </label>
                        </div> :
                        <div className={ cover ? "show_cover" : "show_cover not_cover" } style={ { backgroundImage: 'url("' + cover + '")' } }>
                            {
                                cover ? null : "Capa não encontrada"
                            }
                            <label>
                                <div className="alt_cover">
                                    <input type="file" onChange={ (e)=>{ CropImg('cover', e.target.files[0], 0) } } accept="image/*" />
                                    Editar capa
                                </div>
                            </label>
                        </div>
                    }

                    <div className="show_img">
                        <div className="file_user">
                            {
                                file == "" ? <Svg_Logo_Abbreviated className="icons" /> :
                                <img al="img" src={ fileStatus ? URL.createObjectURL(file) : file } className="icons" />
                            }                        
                        </div>
                        <div className="alt_file" data-tooltip-id="alt_file_user" data-tooltip-content="Alterar sua foto" data-tooltip-place="top">
                            <label>
                                <input type="file" onChange={ (e)=>{ CropImg('file', e.target.files[0], 0) } } accept="image/*" />
                                <Svg_Edit className="icons" color={ colorIcon } />
                            </label>
                        </div>
                        <Tooltip id="alt_file_user" />
                    </div>
                </div>
                :
                null
            }
            
            <div className="div_btn_save">
                <div className="topic_return_admin" onClick={ ()=>{ props.ReturnPage('assistants') } }>
                    <Svg_Arrow className="icons" color={ colorIcon } />
                    Voltar
                </div>
                <div className="" style={ { flexGrow: 1 } } />
                <button className="btn_save">Salvar dados editados</button>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados do login</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setUserName(e.target.value) } } value={ userName } maxLength={ 140 } />
                        <span className="name_input">Nome</span>
                    </div>
                    <div className="div_input">
                        <input type="email" maxLength={ 60 } onChange={ (e)=>{ setUserEmail(e.target.value.replaceAll(' ', '')) } } value={ userEmail } />
                        <span className="name_input">E-mail</span>
                    </div>
                    <div className="div_input">
                        <input type="password" onChange={ (e)=>{ setUserPassword(e.target.value.replaceAll(' ', '')) } } value={ userPassword } required={ idGiven == 0 ? true : false } />
                        <span className="name_input">Senha</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados pessoais</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength={ 140 } />
                        <span className="name_input">Nome</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="cpf" placeholder="000.000.000-00" maxLength={ 16 } onChange={ (e)=>{ setCpf(e.target.value) } } value={ cpfMask(cpf) } />
                        <span className="name_input">CPF</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Endereço</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input">
                        <input type="text" className="cep" placeholder="00000-000" maxLength={ 9 } onChange={ (e)=>{ setCep(e.target.value.replaceAll(' ', '')) } } value={ cepMask(cep) } />
                        <span className="name_input">CEP</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="uf" maxLength={ 2 } onChange={ (e)=>{ setUf(e.target.value.replaceAll(' ', '')) } } value={ uf } />
                        <span className="name_input">UF</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } />
                        <span className="name_input">Cidade</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } />
                        <span className="name_input">Bairro</span>
                    </div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setRoad(e.target.value) } } value={ road } />
                        <span className="name_input">Rua</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="number" onChange={ (e)=>{ setNumber(e.target.value.replaceAll(' ', '')) } } value={ number } />
                        <span className="name_input">Número</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Contato</div>
                    <div className="btn_save" onClick={ ()=>{ setListContact([...listContact, { "id": 0, "name": "", "email": "", "contact": "", "whatsapp": "" }]) } }>Adicionar novo contato</div>
                </div>
                
                {
                    listContact.length > 0 ?
                        listContact.map((elem, index)=>{
                            return(
                                <div className="list_input_data div_line_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('name', index, e.target.value.replaceAll(' ', '')) } } value={ elem.name } maxLength={ 140 } />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="email" className="" onChange={ (e)=>{ HandleData('email', index, e.target.value.replaceAll(' ', '')) } } value={ elem.email } maxLength={ 60 } />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('contact', index, e.target.value) } } value={ elem.contact } maxLength={ 40 } />
                                        <span className="name_input">Contato</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('whatsapp', index, e.target.value) } } value={ elem.whatsapp } maxLength={ 30 } />
                                        <span className="name_input">Whatsapp</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ Delete(index, elem.id, elem.name) } }>
                                        <Svg_Delete className="icons" color="#f00000" />
                                    </div>
                                </div>
                            )
                        })
                    :
                    <div className="no_data">Nenhum contato encontrado...</div>
                }
            </div> 

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Redes Sociais</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setFacebook(e.target.value.replaceAll(' ', '')) } } value={ facebook } />
                        <span className="name_input">Facebook</span>
                    </div>
                    {
                        facebook !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_facebook" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ facebook } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_facebook" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setInstagram(e.target.value.replaceAll(' ', '')) } } value={ instagram } />
                        <span className="name_input">Instagram</span>
                    </div>
                    {
                        instagram !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_instagram" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ instagram } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_instagram" />
                        </>
                        : null
                    }
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Páginas</div>
                </div>
                <div className="list_input_data">
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Página</th>
                                <th width="200">Tipo de acesso</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                page.map((elem, index)=>{
                                    if(elem.name == 'Downloads'){
                                        return(
                                            elem.list_sub.map((elem_1, index_1)=>{
                                                return(
                                                    <tr key={ index_1 }>
                                                        <td>Downloads - { elem_1.name }</td>
                                                        <td>
                                                            <select className="type_access" onChange={ (e)=>{ HandleDataPage('type', index, e.target.value, index_1) } } value={ elem_1.type }>
                                                                <option value="hide_page">Sem acesso</option>
                                                                <option value="show_only">Somente visualização</option>
                                                                <option value="edit">Pode editar</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }else {
                                        return(
                                            <tr key={ index }>
                                                <td>{ elem.name }</td>
                                                <td>
                                                    <select className="type_access" onChange={ (e)=>{ HandleDataPage('type', index, e.target.value, 'only') } } value={ elem.type }>
                                                        <option value="hide_page">Sem acesso</option>
                                                        <option value="show_only">Somente visualização</option>
                                                        <option value="edit">Pode editar</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    )
}