import { useState, useEffect } from "react";

import { Svg_Arrow, Svg_Logo, Svg_User } from "components/Svg";

import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import Page_Downloads from "pages/Downloads";
import Page_Architects_Profile from "pages/Architect/Profile";
import Page_Shopkeepers_Profile from "pages/Shopkeepers/Profile";
import Page_Administrator_Profile from "pages/Administrator/Profile";
import Page_Administrator_Assistants_Details from "pages/Administrator/Assistants/Details";
import Page_Administrator_ApprovalRegistrations from "pages/Administrator/ApprovalRegistrations";

export default function Contents_Assistants(props){

    const [ accessPage, setAccessPage ] = useState(GetUserData('page'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));

    function ReturnPage(value){
        SetListPag('currentPage', value);
        SetListPag('currentPageId', 'remuve');
    }

    function CurrentPage(){
        switch (currentPage) {
            case "architects":
                return <Page_Administrator_Profile name="Arquitetos" origin="architects" setLoading={ props.setLoading } />;
            case "administrator_architects_details":
                return <Page_Architects_Profile origin="admin" idPage={ idPage } ReturnPage={ ReturnPage } setLoading={ props.setLoading } />;

            case "shopkeepers":
                return <Page_Administrator_Profile name="Lojistas" origin="shopkeepers" setLoading={ props.setLoading } />;
            case "administrator_shopkeepers_details":
                return <Page_Shopkeepers_Profile origin="admin" idPage={ idPage } ReturnPage={ ReturnPage } setLoading={ props.setLoading } />;

            case "assistants_details": case "index":
                return <Page_Administrator_Assistants_Details name="" origin="assistants" ReturnPage={ ReturnPage } setLoading={ props.setLoading } />;

            case "downl_images":
                return <Page_Downloads type="block" name="Imagens" origin="images" setLoading={ props.setLoading } />;

            case "downl_catalogs":
                return <Page_Downloads type="block" name="Catálogos" origin="catalogs" setLoading={ props.setLoading } />;

            case "downl_3d_blocks":
                return <Page_Downloads type="block" name="3D Blocos" origin="3D_blocks" setLoading={ props.setLoading } />;

            case "downl_manuals":
                return <Page_Downloads type="block" name="Manuais" origin="manuals" setLoading={ props.setLoading } />;

            case "downl_certificates":
                return <Page_Downloads type="block" name="Certificados" origin="certificates" setLoading={ props.setLoading } />;

            case "approval_of_registrations":
                return <Page_Administrator_ApprovalRegistrations type="block" setLoading={ props.setLoading } />;
        }
    }

    useEffect(()=>{
        RegisterUserData('page', setAccessPage);
        RegisterListPag('currentPage', setCurrentPage);
        RegisterListPag('currentPageId', setIdPage);
        return ()=>{
            UnRegisterListPag('currentPage', setCurrentPage);
            UnRegisterListPag('currentPageId', setIdPage);
            UnRegisterUserPage('page', setAccessPage);
        };
    }, []);

    return(
        <div className="Contents">
            <div className="div_menu">
                <div className="list_menu">
                    <div className="div_logo">
                        <Svg_Logo className="logo" color="#ffffff" />
                    </div>
                    <div className="opt_menu show_data_user">
                        <div className="div_data_img">
                            {
                                props.userFile == "" ? 
                                <Svg_User className="img_user" color="#ffffff" /> :
                                <img alt="user" src={ props.userFile } className="img_user" />
                            }
                        </div>
                        <div className="div_data_user">
                            <div className="hello">Olá,</div>
                            <div className="name_user">
                                {
                                    props.userName.split(' ')[0]
                                }
                                {
                                    props.userName.split(' ')[1] != undefined ? props.userName.split(' ')[1] : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="opt_menu show_opt_menu">
                        <div className={ currentPage == "index" || currentPage == "assistants_details" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage('index') } }>
                            Editar perfil
                        </div>
                        {
                            accessPage.map((elem, index)=>{                    
                                if(elem.name == 'Downloads'){
                                    let showSub = elem.list_sub.filter(item => item.type != "hide_page");
                                    if(showSub.length > 0){
                                        return(
                                            <div className="name_menu" key={ index }>
                                                <div className="submenu">Downloads</div>
                                                {
                                                    elem.list_sub.map((elem_1, index_1)=>{
                                                        if(elem_1.type !='hide_page'){
                                                            return(                                                            
                                                                <div className={ currentPage == elem_1.page ? "submenu menu_active" : "submenu" } key={ index_1 } onClick={ ()=>{ props.CkickPage(elem_1.page) } }>
                                                                    <Svg_Arrow className="icons" color="#ffffff" />
                                                                    { elem_1.name }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                    
                                }else {
                                    if(elem.type !='hide_page'){
                                        return (
                                            <div className={ currentPage == elem.page || currentPage == "administrator_" + elem.page + "_details" ? "name_menu menu_active" : "name_menu" } onClick={ ()=>{ props.CkickPage(elem.page) } } key={ index }>
                                                { elem.name }
                                            </div>
                                        )
                                    }
                                }
                            })
                        }

                        <div className="name_menu exit" onClick={ ()=>{ props.Logout() } }>Sair</div>
                    </div>
                </div>
            </div>
            <div className="div_options">
                { CurrentPage() }
            </div>
        </div>
    )
}