import './PageTopic.css';

import { colorIcon } from 'fixedData';

import { Svg_Add, Svg_Historic, Svg_Search } from "components/Svg";

import { SetModalData, SetModalState } from 'interface/PopUp';

export default function PageTopic(props){
    
    return(
        <div className="new_block">
            <div className="div_search">
                <input type="text" className="search" onChange={ (e)=>{ props.SearchInput(e.target.value) } } placeholder="Digite sua pesquisa..." />
                <Svg_Search className="icons" color="#606875" />
            </div>

            <div className="title_page">{ props.titlePage }</div>
            {
                props.type == "none" ? null : 
                <div className="list_opt_topic">
                    {
                        props.historic.length > 0 ?
                        <div className="" onClick={ ()=>{ SetModalData('Historic', { "listData": props.historic } ); SetModalState('Historic', true); }}>
                            <Svg_Historic className="icons" color={ colorIcon } />
                        </div>
                        : null
                    }
                    <div className="div_new_data" onClick={ ()=>{ props.PageClick(0) } }>
                        <div className="new_data_add">
                            <Svg_Add className="icons" color="#ffffff" />
                        </div>
                        <div className="name_btn_add">Novo cadastro</div>
                    </div>
                </div>
            }
        </div>
    )
}