import { useState, useEffect } from "react";

import './Projects.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PageTopic from "components/PageTopic";
import { Svg_Delete, Svg_Edit } from "components/Svg";

import { SetListPag } from "interface/Page";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { colorIcon } from "fixedData";

export default function Page_Architect_Projects(props){

    const [ search, setSearch ] = useState('');
    const [ currentData, setCurrentData ] = useState(GetDataPage('project'));

    const [ listOn, setListOn ] = useState(InitialData('all'));
    const [ listOff, setListOff ] = useState(InitialData('deleted'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listOn.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listOn.slice(startItens, endItens);
    
    function InitialData(type){
        if(type == "all"){
            const newData = GetDataPage('project').filter(item => item.id_given == GetUserData('id'));
            return newData;
        }
        if(type == "deleted"){
            const newData = [];
            GetDataPage('project').map((elem, index)=>{
                if(elem.status == 0){
                    newData.push({ "id": elem.id, "type": 'architects', "title": elem.name, "removed_name": elem.removed_name, "removed_date": elem.removed_date })
                }
            });
            return newData;
        }
        return [];
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('project').forEach(item =>{
                if(item.status == 1){
                    if(item.id_given == GetUserData('id')){
                        if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(item);
                        }
                        if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(item);
                        }
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListOn(duplicate);
        }else {
            setListOn(InitialData());
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'architects_details');
        SetListPag('currentPageId', idPage);
    }

    function LimitText(text) {
        if (text.length > 200) {
            let showText = text.substring(0, 200) + "..."
            text = <div className="" dangerouslySetInnerHTML={ { __html: showText.replaceAll('&#34;', '"') + "..." } } />;
        }
        return <div className="" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />;
    }

    function DeleteProject(id, value){
        SetModalData('Confirmation', { "id": id, "origin": "architects", "type": "delete_project", "status": 0, "name": value });
        SetModalState('Confirmation', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    useEffect(()=>{
        RegisterDataPage('project', setCurrentData);
        return ()=>{
            UnRegisterDataPage('project', setCurrentData);
        };
    }, []);

    useEffect(()=>{
        setCurrentData(GetDataPage('project'));
        
        setListOn(InitialData('all'));
        setListOff(InitialData('deleted'));
    }, [currentData]);

    return(
        <div className="width_page">
            <PageTopic type="block" PageClick={ PageClick } SearchInput={ SearchInput } titlePage="Projetos" historic={ listOff.length } />

            <div className="list_project">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th>Descrição</th>
                            <th width="40">Capa</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.title }</td>
                                        <td>{ LimitText(elem.text)}</td>
                                        <td align="center">
                                            <img alt="Cover" src={ elem.cover } className="icons_cover" onClick={ ()=>{ OpenFile('showImg', elem.cover) } }/>
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ DeleteProject(elem.id, elem.title) } } data-tooltip-id={ "id_delete_" + elem.id } data-tooltip-content="Deletar projeto" data-tooltip-place="top">
                                                    <Svg_Delete className="icons" color="#F00000" />
                                                </div>
                                                <Tooltip id={ "id_delete_" + elem.id } />

                                                <div onClick={ ()=>{ PageClick(elem.id, elem.title, elem.file) } } data-tooltip-id={ "id_edit_" + elem.id } data-tooltip-content="Editar projeto" data-tooltip-place="top">
                                                    <Svg_Edit className="icons" color={ colorIcon } />
                                                </div>
                                                <Tooltip id={ "id_edit_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td className="no_data" align="center" colSpan={ 5 }>Nenhum cadastro encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    listOn.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}