import { useState, useEffect } from "react";

import './ApprovalRegistrations.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PageTopic from "components/PageTopic";
import { Svg_Delete, Svg_Disabled } from "components/Svg";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Page_Administrator_ApprovalRegistrations(props){

    const [ search, setSearch ] = useState('');
    const [ currentData, setCurrentData ] = useState(GetDataPage('access_dash'));
    const [ showData, setShowData ] = useState(InitialData());
    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(showData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);

    function InitialData(){
        const newData = GetDataPage('access_dash').filter(item => item.status == 3);
        if(newData){
            return newData;
        }
        return [];
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('access_dash').forEach(item =>{
                if(item.status == 3){
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(InitialData());
        }
        setSearch(value);
    }

    function AltProfile(type, id, value){
        SetModalData('Confirmation', { "id": id, "origin": 'approval_registrations', "type": type, "status": 0, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setCurrentData);
        return ()=>{
            UnRegisterDataPage('access_dash', setCurrentData);
        };
    }, []);

    useEffect(()=>{
        setCurrentData(GetDataPage('access_dash'));
        
        setShowData(InitialData());
    }, [currentData]);

    return(
        <div className="width_page">
            <PageTopic type="none" PageClick={ ()=>{ } } SearchInput={ SearchInput } titlePage="Aprovação de Cadastros" historic={ [] } />

            <div className="list_project">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th width="100">Tipo de acesso</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td>{ elem.email }</td>
                                        <td>
                                            { elem.nivel == 0 ? "Lojistas" : "Arquitetos" }
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ AltProfile('delete_user', elem.id, elem.name) } } data-tooltip-id={ "id_delete_" + elem.id } data-tooltip-content="Negar acesso" data-tooltip-place="top">
                                                    <Svg_Delete className="icons" color="#F00000" />
                                                </div>
                                                <Tooltip id={ "id_delete_" + elem.id } />

                                                <div onClick={ ()=>{ AltProfile('approve_user', elem.id, elem.name) } } data-tooltip-id={ "id_access_" + elem.id } data-tooltip-content="Liberar acesso" data-tooltip-place="top">
                                                    <Svg_Disabled className="icons" color="#F00000" />
                                                </div>
                                                <Tooltip id={ "id_access_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td className="no_data" align="center" colSpan={ 5 }>Nenhum cadastro encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}